import {createStore} from 'vuex';
import api from '../helpers/api';
import router from '@/router';

export interface State {
  searchSettings: {
    searchType: string;
    search: string | number;
    searchStrType: string;
    isExactMatch: boolean;
  };
  searchData: any;
  pageCount: number;
  isDataGotten: boolean;
  isLoadingInProgress: boolean;
}

export default createStore<State>({
  state: {
    searchSettings: {
      searchType: 'Metadata',
      search: '',
      searchStrType: 'meta',
      isExactMatch: false,
    },
    searchData: null,
    pageCount: 0,
    isDataGotten: false,
    isLoadingInProgress: false
  },
  getters: {
    getSearchSettings: (state) => state.searchSettings,
    getIsDataGotten: (state) => state.isDataGotten,
    getSearchData: (state) => state.searchData,
    getPageCount: (state) => state.pageCount,
    getIsLoadingInProgress: (state) => state.isLoadingInProgress,
  },
  mutations: {
    setSearchSettings(state, data) {
      state.searchSettings = data
    },
    setIsDataGotten(state, isGotten) {
      state.isDataGotten = isGotten
    },
    setIsLoadingInProgress(state, isLoading) {
      state.isLoadingInProgress = isLoading
    },
    setSearchData(state, data) {
      state.pageCount = data.next ? data.next.count : 1
      state.searchData = data
    },
    resetSearchData(state) {
      state.searchData = {}
    }
  },
  actions: {
    searchByHashOrMeta(state, {type, searchStr, limit, skip, searchSettings}) {
      this.commit('setIsDataGotten', false);
      const data = { type, searchStr, limit, skip };
      this.commit('setIsLoadingInProgress', true);

      return api.searchByHashOrMeta(type, searchStr, limit, skip)
        .then((res) => {
          const data = res.data;
          this.commit('setIsDataGotten', true);
          this.commit('setSearchData', data);
          this.commit('setSearchSettings', searchSettings);
          router.push('/search-result');

          this.commit('setIsLoadingInProgress', false);
        })
        .catch(() => {
          this.commit('setIsLoadingInProgress', false);
        })
    },
    searchByBlockNumbers(state, data) {
      const { blockNumbers } = data;

      if (blockNumbers.length === 1) {
        this.commit('setSearchData', {});

        router.push({
          name: 'block',
          params: { number: blockNumbers[0] },
          query: { key: Math.random().toString() }
        });
        return;
      }

      this.commit('setIsDataGotten', false);
      this.commit('setIsLoadingInProgress', true);

      return api.searchByBlockNumbers(blockNumbers)
        .then((res) => {
          const data = res.data;
          this.commit('setIsDataGotten', true);
          this.commit('setSearchData', { ...data });
          router.push({ name: 'blocks' });
          this.commit('setIsLoadingInProgress', false);

        })
        .catch(() => {
          this.commit('setIsLoadingInProgress', false);
        })
    }
  }
})
