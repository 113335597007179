<template>
 <header class="bg-white header">
   <nav class="px-5 pt-10 pb-5 md:p-10">
     <div class="w-full lg:w-10/12 pb-1 mx-auto">
       <div class="nav-grid-cont items-center grid md:grid-cols-1 md:grid-cols-2 md:grid-rows-1">
         <div class="flex mx-0 space-x-7">
           <div class="mx-auto md:mx-0 mb-7 md:mb-0">
             <!-- Website Logo -->
             <router-link to="/" class="flex items-center router-link-active">
               <img src="../../public/img/geeq-explore-14-14.png" alt="Logo" class="logo inline-block h-1/2">
             </router-link>
           </div>
         </div>
         <search-form />
       </div>
     </div>
   </nav>
 </header>
</template>

<script>
import SearchForm from '@/components/SearchForm';

export default {
  name: 'HeaderComponent',
  components: { SearchForm },
  data: () => ({
    //
  }),
  methods: {
    //
  }
}
</script>

<style>
.header {
  border: 1px solid #329FFB;
  /*min-height: 239px;*/
}
.header .search-form .selector {
  border: 2px solid #F6F6F6 !important;
  padding: 13px 25px 13px 15px;
}
.header .search-form .search-input {
  background: #F6F6F6 !important;
  margin-left: 0;
}
.header .search-form ul {
  border: 1px solid #F6F6F6 !important;
}
.header .search-form .checkcontainer {
  padding-left: 25px;
}
.nav-grid-cont {
  grid-template-columns: 50% 50% !important;
}
@media (max-width: 992px) {
  .nav-grid-cont {
    grid-template-columns: 40% 60% !important;
  }
}
@media (max-width: 768px) {
  .nav-grid-cont {
    grid-template-columns: 100% !important;
  }
}
</style>
