import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Wrapper from '@/views/Layouts/LayoutWrapper.vue'

function load(path: string) {
  // '@' is aliased to src/components
  return () => import(`@/views/${path}.vue`)
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: Wrapper,
    children: [
      {
        path: '/',
        name: 'home',
        component: load('HomeComponent'),
        meta: {
          protected: true,
          layout: 'home'
        }
      },
      {
        path: '/export',
        name: 'export',
        component: load('ExportComponent'),
        meta: {
          protected: true,
          layout: 'home'
        }
      },
      {
        path: 'search-result',
        name: 'search-result',
        component: load('SearchResult'),
        meta: {
          protected: false,
          layout: 'results'
        }
      },
      {
        path: 'transaction/:notaryId?',
        name: 'transaction',
        component: load('TransactionExplorer'),
        meta: {
          protected: false,
          layout: 'results'
        }
      },
      {
        path: 'ref-hash-transaction/:notaryId?',
        name: 'ref-hash-transaction',
        component: load('TransactionExplorer'),
        meta: {
          protected: false,
          layout: 'results'
        }
      },
      {
        path: 'block/:number?',
        name: 'block',
        component: load('BlockExplorer'),
        meta: {
          protected: false,
          layout: 'results'
        }
      },
      {
        path: 'blocks',
        name: 'blocks',
        component: load('BlocksResult'),
        meta: {
          protected: false,
          layout: 'results'
        }
      },
      {
        path: '/:pathMatch(.*)*',
        name: 'page-not-found',
        component: load('PageNotFound'),
        meta: {
          notFound: true,
          protected: false,
          layout: 'home'
        }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    // return desired position
    return { top: 0 }
  }
})
export default router




