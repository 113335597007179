import axios from 'axios';

// create an axios instance with default options
const http = axios.create({ baseURL: window.location.origin });
// const http = axios.create({ baseURL: "http://localhost:3345" });
export function setHeader(key: string, value: string) {
    http.defaults.headers.common[key] = value
}

export default {
  searchByHashOrMeta(type: string, searchStr: string, limit: number, skip: number) {
    let searchParam = searchStr;
    if (type !== "hash") {
      searchParam = encodeURIComponent(searchStr)
    }
    return http.get(`/api/blocks?type=${type}&search=${searchParam}&limit=${limit}&skip=${skip}`)
  },
  searchByBlockNumbers(numbers: Array<number>) {
    return http.get(`/api/blocks/byNumber`, {
      params:{
        numbers: numbers,
      }
    })
  },
  searchByBlockNumber(number: number, limit: number, skip: number) {
    return http.get(`/api/blocks/${number}?limit=${limit}&skip=${skip}`)
  },
  getDocument(id: number) {
      return http.get(`/api/document/${id}`)
  },
  getBlockByNumber(number: number) {
    return http.get(`/api/blocks/${number}`)
  },
  getTransactionDetailsByHash(hash: string) {
    return http.get(`/api/transactions?hash=${hash}`)
  },
  getTransactionDetailsByNotaryId(notaryPayloadId: number) {
    return http.get(`/api/transactions?notaryPayloadId=${notaryPayloadId}`)
  },
  getTransactionDetailsByRefHash(txRefHash: string) {
    return http.get(`/api/transactions/ref-hash-transaction?txRefhash=${txRefHash}`)
  },
  getCsv(from: number, to: number) {
    return http.get(`/api/export/transaction?from=${from}&to=${to}`)
  },
};
