<template>
  <div id="app-container">
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      //
    }
  },
}
</script>

<style>
#app-container {
  background: #f8f8f8;
  height: 100vh;
}

div.header-bar-left img {
  width: auto;
  height: auto;
  position: relative;
  bottom: -10px;
  transform: translate(0px, 10px);
}
div#nav a {
  margin-right: 1.5vw;
  font-size: 20px;
  color: #231f20;
}
#nav a.router-link-exact-active {
  color: #479997;
}
</style>



