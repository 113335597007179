<template>
  <div class="my-container">
    <main>
      <slot />
    </main>
    <FooterComponent />
  </div>
</template>

<script>
import FooterComponent from '../../components/FooterComponent'
export default {
  components: {
    FooterComponent
  }
}
</script>
<style>
.my-container {
  display: flex;
  height: 100vh;
  flex-direction: column;
}
main {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background: rgb(248, 248, 248)
}
</style>
