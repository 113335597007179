<template>
  <footer class="bg-gray-light">
    <div>
      <img src="../../public/img/border-footer.svg" class="w-full" />
    </div>

    <div class="text-sm footer-container grid md:grid-cols-footer grid-cols-2 border-t-4 border-white p-5">
      <div
        class="md:text-left text-center mx-1.5 my-1"
      >
        <a
          href="https://geeq.io/"
          target="_blank"
          class="text-sm text-blue-dark"
        >
          {{ copyright }}
        </a>
      </div>
      <div class="text-center mx-1.5 my-1" style="flex-grow: 1">
        <a
          href="https://geeq.io/privacy-policy/"
          target="_blank"
          class="text-sm text-blue jost-medium"
        >
          Privacy Policy
        </a>
      </div>
      <div class="text-center mx-1.5 my-1" style="flex-grow: 1">
        <a
          href="https://geeq.atlassian.net/servicedesk/customer/portal/1"
          target="_blank"
          class="text-sm text-red jost-medium"
        >
          Service Desk
        </a>
      </div>
      <div
        class="md:text-right text-center mx-1.5 my-1"
      >
        <span class="jost text-blue-dark text-right"><span
          class="text-black">Geeq Block Explorer</span> v{{ appVersion }}</span>
        <span
          class="text-blue-dark"
        >
      </span>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterComponent',
  data() {
    return {
      appVersion: process.env.VUE_APP_VERSION,
      copyright: `© Geeq.io ${process.env.VUE_APP_COPYRIGHT_YEAR}`,
    }
  }
}
</script>
<style>
* {
  font-family: "Jost", sans-serif;
}
/*footer {*/
/*  max-height: 100px;*/
/*}*/
</style>
