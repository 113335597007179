<template>
  <!--        Search section start-->
  <form class="search-form">
    <div class="flex items-center justify-center">
      <div class="aselect" :data-value="searchSettingsObj.searchType" :data-list="searchOptions">
        <div class="selector" id="dropdown" @click="visible=!visible">
          <div class="label">
            <span class="text-blue-dark text-sm">{{ searchSettingsObj.searchType }}</span>
          </div>
          <div class="arrow" :class="{ expanded : visible }"></div>
          <div :class="{ hidden : !visible, visible }">
            <ul>
              <li
                :class="{ current : item === searchSettingsObj.searchType }"
                class="text-sm font-normal"
                v-for="item in searchOptions"
                :key="item"
                @click="searchSettingsObj.searchType=item"
              >
                {{ item }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <input
        :class="`search-input bg-white appearance-none w-full focus:outline-none placeholder-italic text-base leading-tight ml-0 md:ml-1 placeholder-gray-medium ${error ? 'text-red' : 'text-gray-dark'}`"
        type="text"
        @input="emptyError()"
        placeholder="Search the blockchain"
        v-model="searchSettingsObj.search"
      >
      <button v-on:keyup.prevent.enter="search" @click="search" :class="`search-btn bg-blue-light ${!isSearchEnabled?'disabled':''}`"></button>
    </div>

    <!--      Settings section start-->
    <div class="settings-box px-2.5 md:px-0">
      <template v-if="searchSettingsObj.searchType==='Metadata'">
        <p-text text="Settings" color-class="text-blue-light" classes="text-sm jost-medium" />
        <div class="flex gap-20">
          <p>
            <input class="with-gap" value="meta" v-model="searchSettingsObj.searchStrType" name="encoding" type="radio" id="utf8">
            <label for="utf8" class="text-blue-dark text-sm">Text (UTF-8)</label>
          </p>
          <p>
            <input class="with-gap" value="meta64" v-model="searchSettingsObj.searchStrType" name="encoding" type="radio" id="base64">
            <label for="base64" class="text-blue-dark text-sm">Binary</label>
          </p>
        </div>
        <div class="pt-2.5" v-if="searchSettingsObj.searchStrType==='meta'">
          <label class="checkcontainer text-blue-dark text-sm">Exact Match
            <input type="checkbox" v-model="searchSettingsObj.isExactMatch">
            <span class="checkmark"></span>
          </label>
        </div>
      </template>
    </div>
    <!--      Settings section end-->
  </form>
</template>

<script>
import "vue-json-pretty/lib/styles.css";
import PText from "./PText";
import { mapGetters } from 'vuex';

export default {
  name: "SearchForm",
  components: {
    PText,
  },
  props: {

  },
  data: () => {
    return {
      loading: false,
      initialLoad: true,
      searchSettingsObj: {
        searchType: 'Metadata',
        search: '',
        searchStrType: 'meta',
        isExactMatch: false,
      },
      searchOptions: ['Metadata', 'Block Number', 'AppCommitHash', 'RefHash'],
      error: "",
      visible: false,
    }
  },
  computed: {
    ...mapGetters({
      searchData: 'getSearchData',
      searchSettings: 'getSearchSettings',
    }),
    isSearchEnabled() {
      return !!this.searchSettingsObj.search
    },
    blockNumbers() {
      let numArray = []
      if (this.searchSettingsObj.search && this.searchSettingsObj.searchType === "Block Number") {
        const term = this.searchSettingsObj.search;
        const regForNumber = new RegExp("^([1-9][0-9]*)$");
        const regNumsWithComma = new RegExp("^[1-9][0-9]*([,][1-9][0-9]*)*$")
        const regNumsWithRange = new RegExp("^([1-9][0-9]*)[-]([1-9][0-9]*)$")
        if (regForNumber.test(term)) {
          numArray.push(parseInt(this.searchSettingsObj.search))
        } else if (regNumsWithComma.test(term)) {
          numArray = this.searchSettingsObj.search.split(",")
          numArray = numArray.map(Number);
        } else if (regNumsWithRange.test(term)) {
          const rangeArr = term.split("-");
          for (let i = parseInt(rangeArr[0]); i <= parseInt(rangeArr[1]); i++) {
            numArray.push(i)
          }
        }
      }
      return numArray
    }
  },
  watch: {
    'searchSettingsObj.searchType': function () {
      if (!this.initialLoad) {
        if (this.searchSettingsObj.search) {
          this.searchSettingsObj.search = ""
        }
        if (this.searchSettingsObj.searchStrType !== 'meta') {
          this.searchSettingsObj.searchStrType = 'meta'
        }
        if (this.searchSettingsObj.isExactMatch) {
          this.searchSettingsObj.isExactMatch = false
        }
      }
      this.initialLoad = false
    },
    visible(dropOpen) {
      if (dropOpen) {
        document.addEventListener('click', this.closeIfClickedOutside);
      }
    }
  },
  created() {
    this.searchSettingsObj = JSON.parse(JSON.stringify(this.searchSettings))
  },
  mounted() {
    this.initialLoad = false
  },
  methods: {
    resetForm() {
      this.searchSettingsObj = {
        searchType: 'Metadata',
          search: '',
          searchStrType: 'meta',
          isExactMatch: false,
      }
    },
    validate() {
      const term = this.searchSettingsObj.search;
      const re = new RegExp("^([1-9][0-9]*)$|^([1-9][0-9]*)[-]([1-9][0-9]*)$|^[1-9][0-9]*([,][1-9][0-9]*)*$");
      if (re.test(term)) {
        const expToTest = new RegExp("^([1-9][0-9]*)[-]([1-9][0-9]*)$");
        const expToTest2 = new RegExp("^[1-9][0-9]*([,][1-9][0-9]*)*$");
        if (expToTest.test(term)) {
          const rangeArr = term.split("-");
          if ((parseInt(rangeArr[1]) <= parseInt(rangeArr[0])) || (parseInt(rangeArr[1]) - parseInt(rangeArr[0]) >= 100)) {
            return false
          }
        } else if (expToTest2.test(term) && this.blockNumbers.length >= 100) {
          return false
        }
        return true
      }
      return false
    },

    search(e) {
      e.preventDefault();
      if (!this.isSearchEnabled) return;
      switch (this.searchSettingsObj.searchType) {
      case "Block Number":
        this.searchForBlockType();
        break;
      case "AppCommitHash":
        this.searchByHashOrMeta("hash", this.searchSettingsObj.search, 4, 0);
        break;
      case "RefHash":
        this.searchByHashOrMeta("refHash", this.searchSettingsObj.search, 4, 0);
        break;
      default: {
        let type = 'meta64'
        if (this.searchSettingsObj.searchStrType === 'meta') {
          type = this.searchSettingsObj.isExactMatch ? 'metaExactMatch' : 'meta'
        }
        this.searchByHashOrMeta(type, this.searchSettingsObj.search, 4, 0);
        break;
      }
      }
    },
    searchForBlockType() {
      if (!this.validate()) {
        this.error = "Input a valid search string";
        return false;
      } else {
        this.$store.commit('setSearchSettings', JSON.parse(JSON.stringify(this.searchSettingsObj)));

        this.searchByBlockNumbers();
      }
    },
    emptyError() {
      this.error = ""
    },
    closeIfClickedOutside(event) {
      if (!document.getElementById('dropdown').contains(event.target)) {
        this.visible = false;
        document.removeEventListener('click', this.closeIfClickedOutside);
      }
    },
    updateSearchParams(data) {
      this.$store.commit('setSearchSettings', JSON.parse(JSON.stringify(this.searchSettingsObj)))
      this.$store.commit('setSearchData', JSON.parse(JSON.stringify(data)))
    },
    searchByBlockNumbers() {
      this.loading = true;
      const data = { blockNumbers: this.blockNumbers };

      this.$store.dispatch('searchByBlockNumbers', data)
        .catch(() => {
          this.$store.commit('resetSearchData');
          this.$toast.error('Something went wrong..', {
            position: 'bottom'
          });
        })
    },
    searchByHashOrMeta(type, searchStr, limit, skip) {
      const data = { type, searchStr, limit, skip, searchSettings: {...this.searchSettingsObj} };

      this.$store.dispatch('searchByHashOrMeta', data)
        .catch((error) => {
          this.updateSearchParams({})
          const msgOptions = { position: 'bottom' };

          if (error.response.status === 400) {
            this.$store.commit('resetSearchData')
            this.$toast.error(error.response.data.error, msgOptions)
            return;
          }
          this.$store.commit('resetSearchData');
          this.$toast.error('Something went wrong..', msgOptions)
        })
    },
  }
}
</script>
